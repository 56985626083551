import _record from "./record";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.record = void 0;
var record_1 = _record;
Object.defineProperty(exports, "record", {
  enumerable: true,
  get: function () {
    return record_1.record;
  }
});
export default exports;