import _Schema from "../../Schema";
import _getErrorMessageForIncorrectType from "../../utils/getErrorMessageForIncorrectType";
import _maybeSkipValidation from "../../utils/maybeSkipValidation";
import _list from "../list";
import _schemaUtils from "../schema-utils";
var exports = {};
var __awaiter = exports && exports.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.set = void 0;
const Schema_1 = _Schema;
const getErrorMessageForIncorrectType_1 = _getErrorMessageForIncorrectType;
const maybeSkipValidation_1 = _maybeSkipValidation;
const list_1 = _list;
const schema_utils_1 = _schemaUtils;
function set(schema) {
  const listSchema = (0, list_1.list)(schema);
  const baseSchema = {
    parse: (raw, opts) => __awaiter(this, void 0, void 0, function* () {
      const parsedList = yield listSchema.parse(raw, opts);
      if (parsedList.ok) {
        return {
          ok: true,
          value: new Set(parsedList.value)
        };
      } else {
        return parsedList;
      }
    }),
    json: (parsed, opts) => __awaiter(this, void 0, void 0, function* () {
      var _a;
      if (!(parsed instanceof Set)) {
        return {
          ok: false,
          errors: [{
            path: (_a = opts === null || opts === void 0 ? void 0 : opts.breadcrumbsPrefix) !== null && _a !== void 0 ? _a : [],
            message: (0, getErrorMessageForIncorrectType_1.getErrorMessageForIncorrectType)(parsed, "Set")
          }]
        };
      }
      const jsonList = yield listSchema.json([...parsed], opts);
      return jsonList;
    }),
    getType: () => Schema_1.SchemaType.SET
  };
  return Object.assign(Object.assign({}, (0, maybeSkipValidation_1.maybeSkipValidation)(baseSchema)), (0, schema_utils_1.getSchemaUtils)(baseSchema));
}
exports.set = set;
export default exports;