import _FindAndReplaceRecordRequest from "./FindAndReplaceRecordRequest";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FindAndReplaceRecordRequest = void 0;
var FindAndReplaceRecordRequest_1 = _FindAndReplaceRecordRequest;
Object.defineProperty(exports, "FindAndReplaceRecordRequest", {
  enumerable: true,
  get: function () {
    return FindAndReplaceRecordRequest_1.FindAndReplaceRecordRequest;
  }
});
export default exports;