var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getHeader = void 0;
function getHeader(headers, header) {
  for (const [headerKey, headerValue] of Object.entries(headers)) {
    if (headerKey.toLowerCase() === header.toLowerCase()) {
      return headerValue;
    }
  }
  return undefined;
}
exports.getHeader = getHeader;
export default exports;