import _CreateSnapshotRequest from "./CreateSnapshotRequest";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreateSnapshotRequest = void 0;
var CreateSnapshotRequest_1 = _CreateSnapshotRequest;
Object.defineProperty(exports, "CreateSnapshotRequest", {
  enumerable: true,
  get: function () {
    return CreateSnapshotRequest_1.CreateSnapshotRequest;
  }
});
export default exports;