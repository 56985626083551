import _undiscriminatedUnion from "./undiscriminatedUnion";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.undiscriminatedUnion = void 0;
var undiscriminatedUnion_1 = _undiscriminatedUnion;
Object.defineProperty(exports, "undiscriminatedUnion", {
  enumerable: true,
  get: function () {
    return undiscriminatedUnion_1.undiscriminatedUnion;
  }
});
export default exports;