import _runtime from "./runtime";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RUNTIME = void 0;
var runtime_1 = _runtime;
Object.defineProperty(exports, "RUNTIME", {
  enumerable: true,
  get: function () {
    return runtime_1.RUNTIME;
  }
});
export default exports;