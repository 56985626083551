import _UpdateFileRequest from "./UpdateFileRequest";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UpdateFileRequest = void 0;
var UpdateFileRequest_1 = _UpdateFileRequest;
Object.defineProperty(exports, "UpdateFileRequest", {
  enumerable: true,
  get: function () {
    return UpdateFileRequest_1.UpdateFileRequest;
  }
});
export default exports;