import _stringLiteral from "./stringLiteral";
import _booleanLiteral from "./booleanLiteral";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.booleanLiteral = exports.stringLiteral = void 0;
var stringLiteral_1 = _stringLiteral;
Object.defineProperty(exports, "stringLiteral", {
  enumerable: true,
  get: function () {
    return stringLiteral_1.stringLiteral;
  }
});
var booleanLiteral_1 = _booleanLiteral;
Object.defineProperty(exports, "booleanLiteral", {
  enumerable: true,
  get: function () {
    return booleanLiteral_1.booleanLiteral;
  }
});
export default exports;