import _Schema from "../../Schema";
import _getErrorMessageForIncorrectType from "../../utils/getErrorMessageForIncorrectType";
import _isPlainObject from "../../utils/isPlainObject";
import _keys from "../../utils/keys";
import _maybeSkipValidation from "../../utils/maybeSkipValidation";
import _enum from "../enum";
import _objectLike from "../object-like";
import _schemaUtils from "../schema-utils";
var exports = {};
var __awaiter = exports && exports.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __rest = exports && exports.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.union = void 0;
const Schema_1 = _Schema;
const getErrorMessageForIncorrectType_1 = _getErrorMessageForIncorrectType;
const isPlainObject_1 = _isPlainObject;
const keys_1 = _keys;
const maybeSkipValidation_1 = _maybeSkipValidation;
const enum_1 = _enum;
const object_like_1 = _objectLike;
const schema_utils_1 = _schemaUtils;
function union(discriminant, union) {
  const rawDiscriminant = typeof discriminant === "string" ? discriminant : discriminant.rawDiscriminant;
  const parsedDiscriminant = typeof discriminant === "string" ? discriminant : discriminant.parsedDiscriminant;
  const discriminantValueSchema = (0, enum_1.enum_)((0, keys_1.keys)(union));
  const baseSchema = {
    parse: (raw, opts) => __awaiter(this, void 0, void 0, function* () {
      return transformAndValidateUnion({
        value: raw,
        discriminant: rawDiscriminant,
        transformedDiscriminant: parsedDiscriminant,
        transformDiscriminantValue: discriminantValue => {
          var _a;
          return discriminantValueSchema.parse(discriminantValue, {
            allowUnrecognizedEnumValues: opts === null || opts === void 0 ? void 0 : opts.allowUnrecognizedUnionMembers,
            breadcrumbsPrefix: [...((_a = opts === null || opts === void 0 ? void 0 : opts.breadcrumbsPrefix) !== null && _a !== void 0 ? _a : []), rawDiscriminant]
          });
        },
        getAdditionalPropertiesSchema: discriminantValue => union[discriminantValue],
        allowUnrecognizedUnionMembers: opts === null || opts === void 0 ? void 0 : opts.allowUnrecognizedUnionMembers,
        transformAdditionalProperties: (additionalProperties, additionalPropertiesSchema) => additionalPropertiesSchema.parse(additionalProperties, opts),
        breadcrumbsPrefix: opts === null || opts === void 0 ? void 0 : opts.breadcrumbsPrefix
      });
    }),
    json: (parsed, opts) => __awaiter(this, void 0, void 0, function* () {
      return transformAndValidateUnion({
        value: parsed,
        discriminant: parsedDiscriminant,
        transformedDiscriminant: rawDiscriminant,
        transformDiscriminantValue: discriminantValue => {
          var _a;
          return discriminantValueSchema.json(discriminantValue, {
            allowUnrecognizedEnumValues: opts === null || opts === void 0 ? void 0 : opts.allowUnrecognizedUnionMembers,
            breadcrumbsPrefix: [...((_a = opts === null || opts === void 0 ? void 0 : opts.breadcrumbsPrefix) !== null && _a !== void 0 ? _a : []), parsedDiscriminant]
          });
        },
        getAdditionalPropertiesSchema: discriminantValue => union[discriminantValue],
        allowUnrecognizedUnionMembers: opts === null || opts === void 0 ? void 0 : opts.allowUnrecognizedUnionMembers,
        transformAdditionalProperties: (additionalProperties, additionalPropertiesSchema) => additionalPropertiesSchema.json(additionalProperties, opts),
        breadcrumbsPrefix: opts === null || opts === void 0 ? void 0 : opts.breadcrumbsPrefix
      });
    }),
    getType: () => Schema_1.SchemaType.UNION
  };
  return Object.assign(Object.assign(Object.assign({}, (0, maybeSkipValidation_1.maybeSkipValidation)(baseSchema)), (0, schema_utils_1.getSchemaUtils)(baseSchema)), (0, object_like_1.getObjectLikeUtils)(baseSchema));
}
exports.union = union;
function transformAndValidateUnion({
  value,
  discriminant,
  transformedDiscriminant,
  transformDiscriminantValue,
  getAdditionalPropertiesSchema,
  allowUnrecognizedUnionMembers = false,
  transformAdditionalProperties,
  breadcrumbsPrefix = []
}) {
  return __awaiter(this, void 0, void 0, function* () {
    if (!(0, isPlainObject_1.isPlainObject)(value)) {
      return {
        ok: false,
        errors: [{
          path: breadcrumbsPrefix,
          message: (0, getErrorMessageForIncorrectType_1.getErrorMessageForIncorrectType)(value, "object")
        }]
      };
    }
    const _a = value,
      _b = discriminant,
      discriminantValue = _a[_b],
      additionalProperties = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
    if (discriminantValue == null) {
      return {
        ok: false,
        errors: [{
          path: breadcrumbsPrefix,
          message: `Missing discriminant ("${discriminant}")`
        }]
      };
    }
    const transformedDiscriminantValue = yield transformDiscriminantValue(discriminantValue);
    if (!transformedDiscriminantValue.ok) {
      return {
        ok: false,
        errors: transformedDiscriminantValue.errors
      };
    }
    const additionalPropertiesSchema = getAdditionalPropertiesSchema(transformedDiscriminantValue.value);
    if (additionalPropertiesSchema == null) {
      if (allowUnrecognizedUnionMembers) {
        return {
          ok: true,
          value: Object.assign({
            [transformedDiscriminant]: transformedDiscriminantValue.value
          }, additionalProperties)
        };
      } else {
        return {
          ok: false,
          errors: [{
            path: [...breadcrumbsPrefix, discriminant],
            message: "Unexpected discriminant value"
          }]
        };
      }
    }
    const transformedAdditionalProperties = yield transformAdditionalProperties(additionalProperties, additionalPropertiesSchema);
    if (!transformedAdditionalProperties.ok) {
      return transformedAdditionalProperties;
    }
    return {
      ok: true,
      value: Object.assign({
        [transformedDiscriminant]: discriminantValue
      }, transformedAdditionalProperties.value)
    };
  });
}
export default exports;