var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SchemaType = void 0;
exports.SchemaType = {
  DATE: "date",
  ENUM: "enum",
  LIST: "list",
  STRING_LITERAL: "stringLiteral",
  BOOLEAN_LITERAL: "booleanLiteral",
  OBJECT: "object",
  ANY: "any",
  BOOLEAN: "boolean",
  NUMBER: "number",
  STRING: "string",
  UNKNOWN: "unknown",
  RECORD: "record",
  SET: "set",
  UNION: "union",
  UNDISCRIMINATED_UNION: "undiscriminatedUnion",
  OPTIONAL: "optional"
};
export default exports;