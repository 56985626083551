import _Schema from "../../Schema";
import _createIdentitySchemaCreator from "../../utils/createIdentitySchemaCreator";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unknown = void 0;
const Schema_1 = _Schema;
const createIdentitySchemaCreator_1 = _createIdentitySchemaCreator;
exports.unknown = (0, createIdentitySchemaCreator_1.createIdentitySchemaCreator)(Schema_1.SchemaType.UNKNOWN, value => ({
  ok: true,
  value
}));
export default exports;