import _any from "./any";
import _boolean from "./boolean";
import _number from "./number";
import _string from "./string";
import _unknown from "./unknown";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unknown = exports.string = exports.number = exports.boolean = exports.any = void 0;
var any_1 = _any;
Object.defineProperty(exports, "any", {
  enumerable: true,
  get: function () {
    return any_1.any;
  }
});
var boolean_1 = _boolean;
Object.defineProperty(exports, "boolean", {
  enumerable: true,
  get: function () {
    return boolean_1.boolean;
  }
});
var number_1 = _number;
Object.defineProperty(exports, "number", {
  enumerable: true,
  get: function () {
    return number_1.number;
  }
});
var string_1 = _string;
Object.defineProperty(exports, "string", {
  enumerable: true,
  get: function () {
    return string_1.string;
  }
});
var unknown_1 = _unknown;
Object.defineProperty(exports, "unknown", {
  enumerable: true,
  get: function () {
    return unknown_1.unknown;
  }
});
export default exports;