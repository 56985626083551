var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isProperty = exports.property = void 0;
function property(rawKey, valueSchema) {
  return {
    rawKey,
    valueSchema,
    isProperty: true
  };
}
exports.property = property;
function isProperty(maybeProperty) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return maybeProperty.isProperty;
}
exports.isProperty = isProperty;
export default exports;