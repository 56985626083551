import _lazy from "./lazy";
import _lazyObject from "./lazyObject";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lazyObject = exports.lazy = void 0;
var lazy_1 = _lazy;
Object.defineProperty(exports, "lazy", {
  enumerable: true,
  get: function () {
    return lazy_1.lazy;
  }
});
var lazyObject_1 = _lazyObject;
Object.defineProperty(exports, "lazyObject", {
  enumerable: true,
  get: function () {
    return lazyObject_1.lazyObject;
  }
});
export default exports;