import _date from "./date";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.date = void 0;
var date_1 = _date;
Object.defineProperty(exports, "date", {
  enumerable: true,
  get: function () {
    return date_1.date;
  }
});
export default exports;