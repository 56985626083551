import _BasicAuth from "./BasicAuth";
import _BearerToken from "./BearerToken";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BearerToken = exports.BasicAuth = void 0;
var BasicAuth_1 = _BasicAuth;
Object.defineProperty(exports, "BasicAuth", {
  enumerable: true,
  get: function () {
    return BasicAuth_1.BasicAuth;
  }
});
var BearerToken_1 = _BearerToken;
Object.defineProperty(exports, "BearerToken", {
  enumerable: true,
  get: function () {
    return BearerToken_1.BearerToken;
  }
});
export default exports;