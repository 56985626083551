import _list from "./list";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.list = void 0;
var list_1 = _list;
Object.defineProperty(exports, "list", {
  enumerable: true,
  get: function () {
    return list_1.list;
  }
});
export default exports;