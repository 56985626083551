import _Fetcher from "./Fetcher";
import _getHeader from "./getHeader";
import _Supplier from "./Supplier";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Supplier = exports.getHeader = exports.fetcher = void 0;
var Fetcher_1 = _Fetcher;
Object.defineProperty(exports, "fetcher", {
  enumerable: true,
  get: function () {
    return Fetcher_1.fetcher;
  }
});
var getHeader_1 = _getHeader;
Object.defineProperty(exports, "getHeader", {
  enumerable: true,
  get: function () {
    return getHeader_1.getHeader;
  }
});
var Supplier_1 = _Supplier;
Object.defineProperty(exports, "Supplier", {
  enumerable: true,
  get: function () {
    return Supplier_1.Supplier;
  }
});
export default exports;