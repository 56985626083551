import _FlatfileClient from "./wrapper/FlatfileClient";
import _api from "./api";
import _environments from "./environments";
import _errors from "./errors";
var exports = {};
var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = exports && exports.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = exports && exports.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FlatfileTimeoutError = exports.FlatfileError = exports.FlatfileEnvironment = exports.FlatfileClient = exports.Flatfile = void 0;
const FlatfileClient_1 = _FlatfileClient;
exports.Flatfile = __importStar(_api);
var FlatfileClient_2 = _FlatfileClient;
Object.defineProperty(exports, "FlatfileClient", {
  enumerable: true,
  get: function () {
    return FlatfileClient_2.FlatfileClient;
  }
});
var environments_1 = _environments;
Object.defineProperty(exports, "FlatfileEnvironment", {
  enumerable: true,
  get: function () {
    return environments_1.FlatfileEnvironment;
  }
});
var errors_1 = _errors;
Object.defineProperty(exports, "FlatfileError", {
  enumerable: true,
  get: function () {
    return errors_1.FlatfileError;
  }
});
Object.defineProperty(exports, "FlatfileTimeoutError", {
  enumerable: true,
  get: function () {
    return errors_1.FlatfileTimeoutError;
  }
});
exports.default = new FlatfileClient_1.FlatfileClient();
export default exports;
export const __esModule = exports.__esModule,
  FlatfileTimeoutError = exports.FlatfileTimeoutError,
  FlatfileError = exports.FlatfileError,
  FlatfileEnvironment = exports.FlatfileEnvironment,
  FlatfileClient = exports.FlatfileClient,
  Flatfile = exports.Flatfile;