import _stringifyValidationErrors from "./stringifyValidationErrors";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.JsonError = void 0;
const stringifyValidationErrors_1 = _stringifyValidationErrors;
class JsonError extends Error {
  constructor(errors) {
    super(errors.map(stringifyValidationErrors_1.stringifyValidationError).join("; "));
    this.errors = errors;
    Object.setPrototypeOf(this, JsonError.prototype);
  }
}
exports.JsonError = JsonError;
export default exports;