import _Schema from "../../Schema";
import _createIdentitySchemaCreator from "../../utils/createIdentitySchemaCreator";
import _getErrorMessageForIncorrectType from "../../utils/getErrorMessageForIncorrectType";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.booleanLiteral = void 0;
const Schema_1 = _Schema;
const createIdentitySchemaCreator_1 = _createIdentitySchemaCreator;
const getErrorMessageForIncorrectType_1 = _getErrorMessageForIncorrectType;
function booleanLiteral(literal) {
  const schemaCreator = (0, createIdentitySchemaCreator_1.createIdentitySchemaCreator)(Schema_1.SchemaType.BOOLEAN_LITERAL, (value, {
    breadcrumbsPrefix = []
  } = {}) => {
    if (value === literal) {
      return {
        ok: true,
        value: literal
      };
    } else {
      return {
        ok: false,
        errors: [{
          path: breadcrumbsPrefix,
          message: (0, getErrorMessageForIncorrectType_1.getErrorMessageForIncorrectType)(value, `${literal.toString()}`)
        }]
      };
    }
  });
  return schemaCreator();
}
exports.booleanLiteral = booleanLiteral;
export default exports;