import _getObjectLikeUtils from "./getObjectLikeUtils";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.withParsedProperties = exports.getObjectLikeUtils = void 0;
var getObjectLikeUtils_1 = _getObjectLikeUtils;
Object.defineProperty(exports, "getObjectLikeUtils", {
  enumerable: true,
  get: function () {
    return getObjectLikeUtils_1.getObjectLikeUtils;
  }
});
Object.defineProperty(exports, "withParsedProperties", {
  enumerable: true,
  get: function () {
    return getObjectLikeUtils_1.withParsedProperties;
  }
});
export default exports;