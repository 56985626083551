import _object from "./object";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.objectWithoutOptionalProperties = void 0;
const object_1 = _object;
function objectWithoutOptionalProperties(schemas) {
  return (0, object_1.object)(schemas);
}
exports.objectWithoutOptionalProperties = objectWithoutOptionalProperties;
export default exports;