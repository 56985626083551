import _UpdateUserRequest from "./UpdateUserRequest";
import _ExchangeTokenRequest from "./ExchangeTokenRequest";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExchangeTokenRequest = exports.UpdateUserRequest = void 0;
var UpdateUserRequest_1 = _UpdateUserRequest;
Object.defineProperty(exports, "UpdateUserRequest", {
  enumerable: true,
  get: function () {
    return UpdateUserRequest_1.UpdateUserRequest;
  }
});
var ExchangeTokenRequest_1 = _ExchangeTokenRequest;
Object.defineProperty(exports, "ExchangeTokenRequest", {
  enumerable: true,
  get: function () {
    return ExchangeTokenRequest_1.ExchangeTokenRequest;
  }
});
export default exports;