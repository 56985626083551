import _set from "./set";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.set = void 0;
var set_1 = _set;
Object.defineProperty(exports, "set", {
  enumerable: true,
  get: function () {
    return set_1.set;
  }
});
export default exports;