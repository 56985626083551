var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.discriminant = void 0;
function discriminant(parsedDiscriminant, rawDiscriminant) {
  return {
    parsedDiscriminant,
    rawDiscriminant
  };
}
exports.discriminant = discriminant;
export default exports;