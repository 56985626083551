import _DeleteAllHistoryForUserRequest from "./DeleteAllHistoryForUserRequest";
import _DeleteMultipleRulesRequest from "./DeleteMultipleRulesRequest";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeleteMultipleRulesRequest = exports.DeleteAllHistoryForUserRequest = void 0;
var DeleteAllHistoryForUserRequest_1 = _DeleteAllHistoryForUserRequest;
Object.defineProperty(exports, "DeleteAllHistoryForUserRequest", {
  enumerable: true,
  get: function () {
    return DeleteAllHistoryForUserRequest_1.DeleteAllHistoryForUserRequest;
  }
});
var DeleteMultipleRulesRequest_1 = _DeleteMultipleRulesRequest;
Object.defineProperty(exports, "DeleteMultipleRulesRequest", {
  enumerable: true,
  get: function () {
    return DeleteMultipleRulesRequest_1.DeleteMultipleRulesRequest;
  }
});
export default exports;