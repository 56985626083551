import _discriminant from "./discriminant";
import _union from "./union";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.union = exports.discriminant = void 0;
var discriminant_1 = _discriminant;
Object.defineProperty(exports, "discriminant", {
  enumerable: true,
  get: function () {
    return discriminant_1.discriminant;
  }
});
var union_1 = _union;
Object.defineProperty(exports, "union", {
  enumerable: true,
  get: function () {
    return union_1.union;
  }
});
export default exports;